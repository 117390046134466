// @ts-nocheck

// External imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import SearchField from './SearchField'; // Import the SearchField component

// Material UI components
import { AppBar, Box, IconButton, MenuItem, Toolbar, Typography, useMediaQuery, Button, Divider } from '@mui/material';

// Material UI icons
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

// Assets
import snackersLogoWhite from '../assets/icons/snackers-logo-white.png';

// Styles
import '../styles/css/App.css';

// API
import apiUrl from '../apiUrl';

const NavigationBar = ({ navigationBarData }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const { checkLoginStatus, userName } = useAuth();
  const { isLoggedIn } = useAuth(); // Access the login status from context
  const isMobile = useMediaQuery('(max-width: 599px)');
  const [isLogoHidden, setLogoHidden] = useState(false);
  const [isMenuHidden, setMenuHidden] = useState(false);
  const [isProductsButtonHidden, setProductsButtonHidden] = useState(false);

  // let searchTerm = '';
  // let searchResults;

  /***********/

  const handleSnacksNavigate = () => {
    if (location.pathname === '/snacks') {
      window.location.reload(); // Refresh the page if already on /snacks
    } else {
      navigate('/snacks'); // Navigate to /snacks if not already there
    }
  };

  /***********/

  const handleMySnacksNavigate = () => {
    if (location.pathname === '/mysnacks') {
      window.location.reload(); // Refresh the page if already on /mysnacks
    } else {
      navigate('/mysnacks'); // Navigate to /mysnacks if not already there
    }
  };

  /***********/

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/logout`, {
        method: 'POST',
        credentials: 'include', // Include credentials (cookies) in the request
      });

      if (response.ok) {
        // Re-check authentication status after logout to update the global state
        await checkLoginStatus();
        sessionStorage.setItem('logoutSuccess', 'true');

        // Check if the user is already on the home page
        if (window.location.pathname === '/') {
          // If already on the home page, reload the page
          window.location.reload();
        } else {
          // If not on the home page, navigate to login page or desired route
          navigate('/'); // Optionally redirect to login page after logout
        }
      } else {
        console.error('Logout failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  /***********/

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = React.useState(false);

  const handleHamburgerMenuToggle = (event) => {
    // Prevents closing the hamburger menu on click
    event.stopPropagation();
    setIsHamburgerMenuOpen((prev) => !prev);
  };

  // Close hamburger menu when clicking outside it
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar')) {
        setIsHamburgerMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  /**********/

  const middleText = () => {
    const componentsToHideProducts = [
      'loginComponent',
      'signupComponent',
      'forgotPasswordComponent',
      'userSettingsComponent',
      'profileComponent',
      'myReviewsComponent',
    ];

    // Check if any of the components exist in navigationBarData
    const shouldHideProducts = componentsToHideProducts.some(
      (component) => component in navigationBarData
    );

    return { shouldHideProducts }; // Return the boolean to control rendering
  };

  /**********/

  return (
    <AppBar
      sx={{
        backgroundColor: '#D97706',
        zIndex: 900, // Set a low z-index for Snackpage Fancybox
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'center',
          backgroundColor: '#D97706',
        }}
      >
        <Box
          sx={{
            maxWidth: '1200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            minHeight: 'inherit',
          }}
        >
          {/* Logo - hidden on small screens when search is active */}
          {!isLogoHidden && (
            <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: '24px' }}>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Box
                  component="img"
                  src={snackersLogoWhite} // Replace with correct logo path
                  alt="Snackers logo"
                  sx={{
                    maxHeight: '40px',
                    cursor: 'pointer',
                  }}
                />
              </Link>
            </Box>
          )}

          {/* Products Button (only visible on screens wider than 599 px and based on navigationBarData logic) */}
          {!isProductsButtonHidden && !isMobile && !middleText().shouldHideProducts && (
            <Box
              sx={{
                minHeight: 'inherit',
              }}
            >
              <Button
                onClick={handleSnacksNavigate}
                component={Link}
                to="/snacks"
                sx={{
                  minHeight: 'inherit',
                  borderRadius: 0,
                  gap: '0.1rem',
                  color: '#FFFBEB',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                  transition: 'background-color 0.1s ease',
                  backgroundColor: location.pathname === '/snacks' ? '#F59E0B' : 'inherit', // Highlight color if on /snacks
                  '&:hover': {
                    backgroundColor: '#F59E0B',
                  },
                }}
              >
                <AppsOutlinedIcon /> {t('products_dropdown')}
              </Button>
            </Box>
          )}

          {/* Searchfield */}
          <Box
            sx={{
              marginLeft: 'auto',
              marginRight: isMobile ? 0 : '24px',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {!middleText().shouldHideProducts ? (
              <SearchField
                isLogoHidden={isLogoHidden}
                setLogoHidden={setLogoHidden}
                setMenuHidden={setMenuHidden} // Pass setMenuHidden to SearchField
                setProductsButtonHidden={setProductsButtonHidden}
              />
            ) : (
              // Placeholder to keep the layout consistent
              <Box sx={{ height: '45px' }} />
            )}
          </Box>

          {/* Right menu button */}
          {!isMenuHidden && ( // Add the !isMenuHidden conditional here
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!isMobile ? (
                // Desktop view
                isLoggedIn ? (
                  // Account icon for logged-in users
                  <IconButton
                    color="inherit"
                    aria-label="accountMenu"
                    edge="end"
                    onClick={handleHamburgerMenuToggle} // Existing function to toggle dropdown
                    sx={{
                      padding: '12px',
                    }}
                  >
                    <AccountCircleOutlinedIcon />
                  </IconButton>
                ) : (
                  // Log in button for users not logged in
                  !middleText().shouldHideProducts && ( // Hide the log in button if any specified components are active
                    <Button
                      variant="contained"
                      onClick={() => navigate('/login')}
                      sx={{
                        bgcolor: '#FFFBEB',
                        color: '#D97706',
                        py: 1,
                        fontSize: '1rem',
                        px: 3,
                        borderRadius: 2,
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                        },
                      }}
                    >
                      {t('log_in_button')}
                    </Button>
                  )
                )
              ) : (
                // Mobile view
                isLoggedIn ? (
                  // Account icon for logged-in users on small screens
                  <IconButton
                    color="inherit"
                    aria-label="accountMenu"
                    edge="end"
                    onClick={handleHamburgerMenuToggle} // Existing function to toggle dropdown
                    sx={{
                      padding: '12px',
                    }}
                  >
                    <AccountCircleOutlinedIcon />
                  </IconButton>
                ) : (
                  // Menu icon for non-logged-in users
                  !middleText().shouldHideProducts && ( // Hide the menu icon if any specified components are active
                    <IconButton
                      color="inherit"
                      aria-label="accountMenu"
                      edge="end"
                      onClick={handleHamburgerMenuToggle} // Existing function to toggle dropdown
                      sx={{
                        padding: '12px',
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )
                )
              )}

              {/* Dropdown menu when the right menu button is pressed */}
              {isHamburgerMenuOpen && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '100%', // Positioned below the AppBar
                    left: 0,
                    width: '100%',
                    backgroundColor: '#FFFBEB',
                    zIndex: 1000,
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dropdown
                >
                  <Box
                    sx={{
                      maxWidth: '1200px', // Limit the total width
                      margin: '0 auto', // Center the content horizontally
                      display: 'flex', // Flexbox for alignment
                      flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on small screens, horizontally on large screens
                      justifyContent: 'end', // Align menu list and caption
                    }}
                  >
                    {/* Menu List */}
                    <ul
                      style={{
                        listStyle: 'none',
                        padding: 0, // Remove padding inside the list
                        margin: 0, // Remove outer margins
                        color: '#4B5563',
                      }}
                    >

                      {isLoggedIn && (
                        <>
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center', // Align the icon and text vertically
                              padding: { xs: '16px', md: '12px' },
                              fontWeight: 600,
                              color: '#1F2937',
                              wordBreak: 'break-word', // Break words if no spaces are available
                              maxWidth: { xs: '100%', md: '200px' }, // Restrict max width to 200px on large screens
                            }}
                          >
                            <PersonOutlineOutlinedIcon sx={{ marginRight: '0.5rem', flexShrink: 0 }} />
                            {userName}
                          </Typography>
                          <Divider variant="middle" />
                        </>
                      )}

                      <MenuItem
                        onClick={handleSnacksNavigate}
                        component={Link}
                        to="/snacks"
                        sx={{
                          padding: { md: '12px' }, // Small padding for small screens, default for larger screens
                        }}
                      >
                        <AppsOutlinedIcon sx={{ marginRight: '0.5rem' }} />
                        {t('snacks_button')}
                      </MenuItem>

                      {isLoggedIn && (
                        <>

                          <MenuItem
                            onClick={handleMySnacksNavigate}
                            component={Link}
                            to="/mysnacks"
                            sx={{
                              padding: { md: '12px' }, // Consistent padding
                            }}
                          >
                            <ListAltOutlinedIcon sx={{ marginRight: '0.5rem' }} />
                            {t('my_snacks_button')}
                          </MenuItem>

                          <MenuItem
                            component={Link}
                            to="/usersettings"
                            sx={{
                              padding: { md: '12px' },
                            }}
                          >
                            <ManageAccountsOutlinedIcon sx={{ marginRight: '0.5rem' }} />
                            {t('user_settings_button')}
                          </MenuItem>

                          <MenuItem
                            onClick={() => handleLogout()}
                            sx={{
                              padding: { md: '12px' },
                            }}
                          >
                            <LogoutOutlinedIcon sx={{ marginRight: '0.5rem' }} />
                            {t('log_out_button')}
                          </MenuItem>
                        </>
                      )}
                    </ul>


                    {/* User control Section */}
                    {!isLoggedIn && (
                      <Box
                        sx={{
                          padding: '1rem',
                          minWidth: '200px', // Maintain a minimum width
                          backgroundColor: '#FEF3C7', // Different background for small screens
                          display: 'flex', // Flexbox for alignment
                          flexDirection: 'column', // Stack items vertically
                          alignItems: 'center', // Center items horizontally
                          justifyContent: 'center', // Center content vertically
                        }}
                      >
                        {/* Logged-out View */}
                        <Button
                          onClick={() => navigate('/signup')} // Navigate programmatically
                          sx={{
                            backgroundColor: '#F59E0B',
                            color: '#FFFBEB',
                            marginBottom: '16px', // Space between button and link
                            padding: '8px 16px',
                            textTransform: 'none',
                            fontSize: '1rem',
                          }}
                        >
                          {t('join_now_button')}
                        </Button>
                        <MenuItem
                          component={Link}
                          to="/login"
                          sx={{
                            color: '#4B5563',
                          }}
                        >
                          {t('already_a_member_link')}
                        </MenuItem>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;

/**********/

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     '&:hover': {
//         backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     margin: '0 1rem 0 1rem',
//     maxWidth: '300px',
//     [theme.breakpoints.up('sm')]: {
//         maxWidth: '300px',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     // @ts-ignore
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     width: '100%',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         // @ts-ignore
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create('width'),
//         [theme.breakpoints.up('sm')]: {
//             width: '20ch',
//             '&:focus': {
//                 width: '20ch',
//             },
//         },
//     },
// }));

/**********/

// Comment out Products megamenu related Functions. 28.11.2024.
//
// const [isProductsDropdownOpen, setIsProductsDropdownOpen] = React.useState(false);

// const handleProductsDropdownToggle = (event) => {
//   // Prevents closing the dropdown immediately on click
//   event.stopPropagation();
//   setIsProductsDropdownOpen((prev) => !prev);
// };

// Close dropdown when clicking outside
// React.useEffect(() => {
//   const handleClickOutside = (event) => {
//     if (!event.target.closest('.navbar')) {
//       setIsProductsDropdownOpen(false);
//     }
//   };

//   document.addEventListener('click', handleClickOutside);
//   return () => document.removeEventListener('click', handleClickOutside);
// }, []);

/**********/

// Comment out this function for now since there is no functionality for the search function. 07.04.2024.
// Function to grab user input from searchbox

// let timeoutId;
// const handleChange = (event) => {
//     clearTimeout(timeoutId); // Clear any existing timeout
//     searchTerm = event.target.value;

//     if (searchTerm.length > 1) {
//         // Set a new timeout to trigger handleSearch after 1 second
//         timeoutId = setTimeout(handleSearch, 1000);
//     }
// };

/**********/

// const [searchResults, setSearchResults] = useState([]);

// const handleSearch = async () => {
//     try {
//         const response = await fetch(`${apiUrl}/searchSnacks?query=${searchTerm}`);
//         if (response.ok) {
//             const data = await response.json();
//             setSearchResults(data);
//             // Process the fetched data as needed
//             console.log(searchResults);
//         } else {
//             console.error('Failed to fetch data:', response.statusText);
//         }
//     } catch (error) {
//         console.error('Error fetching data:', error.message);
//     }
// };

/**********/

// const leftIcon = () => {
//     if ('loginComponent' in navigationBarData) {
//         return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => navigate("/")}>
//             <ArrowBackIcon />
//         </IconButton>
//     } else if ('signupComponent' in navigationBarData) {
//         return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => navigate("/login")}>
//             <ArrowBackIcon />
//         </IconButton>
//     } else if ('forgotPasswordComponent' in navigationBarData) {
//         return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => navigate("/login")}>
//             <ArrowBackIcon />
//         </IconButton>
//     } else {
//         return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleMenu}>
//             <MenuIcon />
//         </IconButton>
//     }
// }

/**********/

// const middleText = () => {
//     if ('loginComponent' in navigationBarData) {
//         return <Typography variant="h6" className='navbar-middletext'>{t('login_header')}</Typography>
//     } else if ('signupComponent' in navigationBarData) {
//         return <Typography variant="h6" className='navbar-middletext'>{t('create_new_user_header')}</Typography>
//     } else if ('forgotPasswordComponent' in navigationBarData) {
//         return <Typography variant="h6" className='navbar-middletext'>{t('forgot_password_header')}</Typography>
//     } else if ('userSettingsComponent' in navigationBarData) {
//         return <Typography variant="h6" className='navbar-middletext'>{t('user_settings_header')}</Typography>
//     } else if ('profileComponent' in navigationBarData) {
//         return <Typography variant="h6" className='navbar-middletext'>{t('profile_header')}</Typography>
//     } else if ('myReviewsComponent' in navigationBarData) {
//         return <Typography variant="h6" className='navbar-middletext'>{t('my_snacks_header')}</Typography>
//     } else {
//         return <Box
//             sx={{
//                 display: { xs: 'flex', sm: 'flex', md: 'flex' },
//                 alignItems: 'center',
//             }}
//         >
//             <Link to="/snacks" sx={{ textDecoration: 'none' }}>
//                 <Box
//                     component="img"
//                     src={snackersLogoWhite}
//                     alt="Snackers logo"
//                     sx={{
//                         maxHeight: '40px',
//                         transition: 'filter 0.05s ease',
//                         '&:hover': {
//                             filter: 'grayscale(100%) invert(1%)',
//                         },
//                     }}
//                     onClick={handleLogoClick} // Add onClick handler
//                 />
//             </Link>
//         </Box>
//     }
// }

/**********/

// Comment out searchbox for now. 07.04.2024.
// return <><div style={{ position: 'relative' }}><Search>
//     <SearchIconWrapper>
//         <SearchIcon />
//     </SearchIconWrapper>
//     <StyledInputBase
//         placeholder={t('search_placeholder')}
//         inputProps={{ 'aria-label': 'search' }}
//         onChange={handleChange}
//     />
// </Search>
// {searchResults.length > 0 && (
//     <Paper elevation={3} style={{
//         position: 'absolute',
//         top: '100%',
//         left: 0,
//         zIndex: 1000, // Adjust the z-index value as needed
//         marginTop: '16px',
//         padding: '16px',
//     }}>
//         {searchResults.map((result) => (
//             <Typography key={result.id} variant="body1">
//                 {/* Render search result item */}
//                 {result.name.fi}
//             </Typography>
//         ))}
//     </Paper>
// )}
// </div>
// </>

/**********/

// Comment out Products megamenu for now since there would be only one link inside it. 28.11.2024.

// {isProductsDropdownOpen && (
//     <Box
//       sx={{
//         position: 'absolute',
//         top: '100%',
//         left: 0,
//         width: '100%',
//         backgroundColor: '#FFFBEB',
//         zIndex: 1000,
//         boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//         padding: '2rem 0',
//       }}
//       onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dropdown
//     >
//       <Box
//         sx={{
//           maxWidth: '1200px',
//           margin: 'auto',
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'flex-start',
//         }}
//       >
//         <Box sx={{ flex: 1, padding: '0 1rem' }}>
//           <Typography
//             sx={{
//               fontSize: '1.2rem',
//               marginBottom: '1rem',
//               marginLeft: '16px',
//               color: '#1F2937',
//             }}
//           >
//             {t('browse_snacks_header')}
//           </Typography>

//           <MenuItem component={Link} to="/snacks" sx={{ color: '#4B5563', maxWidth: 'fit-content' }}>
//             {t('snacks_list_item')}
//           </MenuItem>
//         </Box>
//       </Box>
//     </Box>
//   )
// }

/**********/