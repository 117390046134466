// React and Hooks
import React, { useState, useEffect, useRef, useCallback } from 'react';

// Third-party libraries
import { useTranslation } from 'react-i18next';

// Material-UI components
import { Box, TextField, IconButton, InputAdornment, useMediaQuery } from '@mui/material';

// Material-UI icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

// Local imports
import apiUrl from '../apiUrl';
import SearchFieldResult from './SearchFieldResult';

// Custom hook for debouncing
const useDebouncedValue = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

const SearchField = ({
    isLogoHidden,
    setLogoHidden,
    setMenuHidden,
    setIsSearchOpen,
    setProductsButtonHidden,
}) => {
    const { t } = useTranslation();
    const [isSearchOpen, setSearchOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const inputRef = useRef(null);
    const containerRef = useRef(null);

    const debouncedQuery = useDebouncedValue(query, 500);

    const isDesktop = useMediaQuery('(min-width:900px)');
    const isTabletOrMobile = useMediaQuery('(max-width:899px)');
    const isMobile = useMediaQuery('(max-width:599px)');

    const handleSearchClick = () => {
        setSearchOpen(true);
        inputRef.current?.focus();

        if (isMobile) {
            setLogoHidden(true);
            setMenuHidden(true);
        }

        if (!isDesktop) {
            setProductsButtonHidden(true);
        }
    };

    const handleCloseSearch = useCallback(() => {
        setSearchOpen(false);

        if (isMobile) {
            setLogoHidden(false);
            setMenuHidden(false);
        }

        setQuery('');
        setResults([]);
    }, [isMobile, setLogoHidden, setMenuHidden]);

    const fetchSearchResults = useCallback(async (searchQuery) => {
        if (!searchQuery.trim()) {
            setResults([]);
            return;
        }

        try {
            const response = await fetch(
                `${apiUrl}/searchfield?search=${encodeURIComponent(searchQuery)}&limit=10&offset=0`
            );
            if (response.status === 204) {
                setResults([{ id: 'no-snacks', message: 'No snacks found.' }]);
            } else if (response.ok) {
                const data = await response.json();
                setResults(data);
            } else {
                setResults([]);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            setResults([]);
        }
    }, []);

    useEffect(() => {
        fetchSearchResults(debouncedQuery);
    }, [debouncedQuery, fetchSearchResults]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                handleCloseSearch();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleCloseSearch]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                position: 'relative',
            }}
        >
            {isDesktop || isSearchOpen ? (
                <TextField
                    inputRef={inputRef}
                    autoFocus={isSearchOpen && !isDesktop}
                    variant="outlined"
                    placeholder={t('searchfield_placeholder')}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    sx={{
                        width: isDesktop ? '400px' : '100%',
                        height: '45px',
                        backgroundColor: '#FFFBEB',
                        borderRadius: 2,
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                        '& .MuiOutlinedInput-root': {
                            height: '45px',
                            borderRadius: 2,
                            paddingRight: '14px',
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#FFFBEB',
                            },
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        if (query.trim()) {
                                            setQuery('');
                                            setResults([]);
                                            inputRef.current?.focus();
                                        } else if (isSearchOpen) {
                                            handleCloseSearch();
                                        } else {
                                            handleSearchClick();
                                        }
                                    }}
                                    edge="end"
                                >
                                    {isSearchOpen || query.trim() ? <CloseIcon /> : <SearchIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ) : (
                <IconButton onClick={handleSearchClick}>
                    <SearchIcon sx={{ color: '#FFFBEB' }} />
                </IconButton>
            )}
            {results.length > 0 && (
                <Box
                    ref={containerRef}
                    sx={{
                        position: 'absolute',
                        top: '100%',
                        width: isTabletOrMobile ? '100%' : '400px',
                        zIndex: 1000,
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: 2,
                        maxHeight: '600px',
                        overflowY: 'auto',
                        backgroundColor: '#FFFBEB',
                    }}
                >
                    {results.map((result, index) => (
                        <SearchFieldResult key={index} snack={result} />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default SearchField;
