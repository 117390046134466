const fi = {
    // Navigation bar component //
    // Left menu //
    home_button: "Etusivu",
    about_button: "Tietoa",
    browse_button: "Selaa",

    // Middle header //
    login_header: 'Kirjaudu sisään',
    create_new_user_header: 'Luo uusi käyttäjä',
    forgot_password_header: "Palauta salasana",
    user_settings_header: "Asetukset",
    profile_header: "Profiili",
    my_snacks_header: "Omat tuotteet",
    search_placeholder: "Hae tuotteita...",

    // Dropdown menus //
    products_dropdown: "Tuotteet",
    browse_snacks_header: "Selaa tuotteita",
    snacks_list_item: "Tuotteet",

    // Searchfield //
    searchfield_placeholder: "Etsi tuotteita...",
    no_snacks_found: "Tuotteita ei löytynyt.",
    //

    // Right Log in button (large screens) //
    log_in_button: "Kirjaudu",

    // Right menu //
    snacks_button: "Selaa",
    login_button: "Kirjaudu",
    join_now_button: "Liity nyt",
    user_profile_button: "Profiili",
    user_settings_button: "Asetukset",
    my_snacks_button: "Omat tuotteet",
    log_out_button: "Kirjaudu ulos",
    user_control_section_hi_message: "Moi",
    already_a_member_link: "Oletko jo käyttäjä? Kirjaudu sisään",
    //

    // Home component //
    frontpage_info: "Tervetuloa Snackersiin. Ennen sivuston käyttöä suosittelen tutustumaan 'Tietoa'-osioon minne pääsee vasemman ylälaidan valikkokuvakkeesta.",
    frontpage_choose_category_text: "Valitse kategoria:",
    frontpage_button_beverages: "Juomat",
    frontpage_button_salty: "Suolaiset",
    frontpage_button_sweets: "Makeat",
    frontpage_button_all: "Kaikki",
    login_success_alert: "Kirjautuminen onnistui!",
    logout_success_alert: "Uloskirjautuminen onnistui!",
    hero_title: "Napostelijoiden Kohtaamispaikka",
    hero_subtitle: "Etsitkö naposteltavaa vaikkapa leffaillalle?",
    signup_box_header: "Haluatko arvostella tuotteita muiden nähtäväksi?",
    signup_box_text: "Kirjaudu tai luo tili",
    signup_box_button: "Kirjautumiseen",
    view_my_snacks_box_header: "Haluatko tarkastella lisäämiäsi tuotteita?",
    view_my_snacks_box_text: "Siirry omiin tuotteisiin",
    view_my_snacks_box_button: "Omat tuotteet",
    view_snacks_box_header: "Haluatko löytää nopeasti jotain naposteltavaa?",
    view_snacks_box_text: "Siirry selaamaan kaikkia tuotteita",
    view_snacks_button: "Selaa tuotteita",
    logged_in_view_snacks_box_text: "Tästä pääset selaamaan naposteltavia:",
    logged_in_view_snacks_box_button: "Selaa",
    logged_in_view_my_snacks_box_text: "Tästä taasen voit selata omia tuotteitasi:",
    logged_in_view_my_snacks_box_button: "Omat tuotteet",
    already_logged_in_alert: "Olet jo kirjautuneena sisään.",
    //

    // Footer //
    language_selection_caption: "Kieli:",
    about_link: "Tietoa",
    //

    // About component //
    about_page_info: "Tämä sivusto on tehty harrastus- ja opettelumielessä. Sivustolle on tarkoitus lisätä arvosteluja haluamistaan tuotteista joita kuka tahansa voi hyödyntää miettiessään mitä syötävää tai juotavaa haluaisi hankkia esimerkiksi leffaillalle. Tuotteita ja arvosteluja voi selata kuka tahansa, mutta uusien lisääminen vaatii käyttäjätunnuksen luomisen.",
    about_page_info_2: "Sivusto on toistaiseksi keskeneräinen joten sen aktiivisempi käyttö kannattanee jättää tulevaisuuteen. Halutessasi voit kuitenkin kokeilla sivuston ominaisuuksia.",
    //

    // Login component //
    log_in_header: "Kirjaudu sisään",
    no_account: "Uusi käyttäjä?",
    signup_link: "Luo tili.",
    login_username_placeholder: "Käyttäjänimi",
    password_placeholder: "Salasana",
    username_empty_error: "Syötä käyttäjänimi.",
    password_empty_error: "Syötä salasana.",
    remember_me: "Muista minut",
    forgot_password: "Unohtuiko salasana?",
    successful_signup_alert: "Tilin luonti onnistui! Voit nyt kirjautua sisään.",
    login_failure_alert: "Kirjautuminen epäonnistui!",
    email_not_confirmed_alert: "Sähköpostiosoitettasi ei ole vielä vahvistettu. Ole hyvä ja tarkista sähköpostiisi saapuneet viestit ja seuraa ohjeita sähköpostiosoitteesi vahvistamiseksi.",
    login_to_see_my_snacks_alert: "Kirjaudu sisään.",
    login_to_change_password_alert: "Kirjaudu sisään.",
    //

    // Signup component //
    sign_up_header: "Luo käyttäjätili",
    signup_button: "Luo käyttäjätili",
    username_placeholder: "Käyttäjänimi",
    email_placeholder: "Sähköposti (valinnainen)",
    password_again_placeholder: "Salasana uudestaan",
    already_have_account: "Minulla on jo käyttäjä.",
    login_link: "Kirjaudu.",
    username_error: "Käyttäjänimen on oltava vähintään 2 merkkiä.",
    email_error: "Virheellinen sähköpostiosoite.",
    password_error: "Salasanan on oltava vähintään 8 merkkiä.",
    password_again_error: "Salasanat eivät täsmää.",
    signup_failure_alert: "Käyttäjän luonti epäonnistui!",
    email_info_text: "Sähköpostia käytetään vain unohtuneen salasanan palauttamiseen. Sähköposti ei näy muille käyttäjille.",
    username_info_text: "Käyttäjänimesi näkyy lisäämiesi naposteltavien ja arvostelujen yhteydessä.",
    //

    // Forgot password component //
    forgot_password_info: "Unohditko salasanasi? Ei huolta, sitä sattuu. Syötä alle tilisi sähköpostiosoite tai käyttäjänimi. Lähetämme ohjeet salasanasi palauttamiseen sähköpostitse.",
    submit_button: "Lähetä",
    back_to_login_link: "Takaisin kirjautumiseen",
    //

    // User settings component//
    change_password_text: "Vaihda salasanasi tässä:",
    change_password_password_placeholder: "Uusi salasana",
    change_password_submit_button: "Lähetä",
    successful_change_password_alert: "Salasana vaihdettu!",
    //

    // Snacks component //
    timestamp_text: "Lisätty: ",
    rating_card_open_button: "Avaa",
    //

    // My snacks component //
    add_new_snack_button: "Lisää arvostelu",
    filter_results_modal_header: "Suodata tuloksia",
    filter_results_modal_checkbox_beverages: "Juomat",
    filter_results_modal_checkbox_salty: "Suolaiset",
    filter_results_modal_checkbox_sweets: "Makeat",
    rating_card_delete_button: "Poista",
    delete_conf_text: "Poistetaanko arvostelu? Tätä toimintoa ei voi peruuttaa.",
    delete_conf_button_yes: "Poista",
    delete_conf_button_no: "Takaisin",
    snacks_not_found_info: "Tuotteita ei löytynyt.",
    //

    // My snacks add new snack modal //
    save_button: "Tallenna",
    cancel_button: "Peruuta",
    add_new_snack_modal_header: "Lisää uusi tuote",
    snack_type_placeholder: "Kategoria*",
    snack_brand_placeholder: "Valmistaja tai tuotemerkki",
    snack_name_placeholder: "Tuotteen nimi",
    rating_text: "Arvostelu: ",
    add_comment_placeholder: "Lisää kommentti... (valinnainen)",
    dropdown_menu_items_beverages: "Juomat",
    dropdown_menu_items_salty: "Suolaiset",
    dropdown_menu_items_sweets: "Makeat",
    add_image_label: "Tuotteen kuva:",
    image_chosen: "Kuva valittu!",
    snack_type_error: "Valitse tuotteelle kategoria.",
    snack_brand_error: "Lisää valmistaja tai tuotemerkki.",
    snack_name_error: "Lisää tuotteen nimi.",
    snack_image_error: 'Lisää kuva tuotteesta.',
    field_empty_server_error: 'Täytä puuttuvat kentät.',
    snack_added_success: 'Tuote lisätty!',
    //

    // Email confirmation component //
    email_confirmed_text: "Sähköpostisi on nyt vahvistettu ja voit kirjautua sisään.",
    //

    // Snackpage component //
    average_rating_info_text: "Tähtien keskiarvo:",
    back_button: "Takaisin",
    review_add_success_alert: "Arvostelu lisätty!",
    image_filesize_too_big_error: "Kuvan tiedostokoko on liian suuri!",
    add_new_review_modal_header: "Uusi arvostelu",
    add_optional_comment_placeholder: "Lisää kommentti... (valinnainen)",
    reviews_not_found_info: "Arvosteluja ei löytynyt.",
    //
}

export default fi;
