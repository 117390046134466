// React and Hooks
import React from 'react';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Material-UI components
import { Card, CardMedia, Typography, Box, Rating } from '@mui/material';

// Local imports
import apiUrl from '../apiUrl';

const SearchFieldResult = ({ snack }) => {
    const { t } = useTranslation();

    // Render a message when no snacks are found
    if (snack.id === 'no-snacks') {
        return (
            <Box
                sx={{
                    backgroundColor: '#FFFBEB',
                    padding: '16px',
                    textAlign: 'center',
                    color: 'text.secondary',
                }}
            >
                <Typography variant="body1">{t('no_snacks_found')}</Typography>
            </Box>
        );
    }

    return (
        <Card
            component={Link}
            to={`/snackpage/${snack.id}`}
            sx={{
                backgroundColor: '#FFFBEB',
                textDecoration: 'none',
                color: 'inherit',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                borderRadius: 0,
                boxShadow: 0,
                '&:hover': {
                    backgroundColor: '#fae2af',
                },
            }}
        >
            {/* Snack Image */}
            {snack.imageUrl && (
                <CardMedia
                    component="img"
                    image={`${apiUrl}${snack.imageUrl}`}
                    alt={snack.productName}
                    sx={{
                        width: 100,
                        height: 100,
                        borderRadius: 2,
                    }}
                />
            )}

            {/* Snack Details */}
            <Box sx={{ padding: '0 1rem' }}>
                {/* Timestamp */}
                <Typography fontSize="0.7rem" color="#4B5563">
                    {t('timestamp_text')} {new Date(snack.timeCreated).toLocaleDateString('en-GB')}
                </Typography>

                {/* Product Brand */}
                <Typography
                    sx={{
                        color: '#4B5563',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {snack.productBrand}
                </Typography>

                {/* Product Name */}
                <Typography
                    sx={{
                        color: '#1F2937',
                        fontWeight: 600,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        lineHeight: 1.2,
                    }}
                >
                    {snack.productName}
                </Typography>

                {/* Rating Section */}
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                            flexWrap: 'wrap', // Allow items to wrap for smaller screens
                        }}
                    >
                        <Typography
                            sx={{ fontSize: '0.8em', marginRight: '0.5em' }}
                            color="#4B5563"
                        >
                            {t('average_rating_info_text')}
                        </Typography>
                        <Typography
                            color="#4B5563"
                            fontSize="0.9rem"
                            sx={{ minWidth: '25px' }}
                        >
                            {snack.averageRating.toFixed(1)}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap', // Allow wrapping for smaller screens
                            alignItems: 'center', // Vertically center the items
                        }}
                    >
                        <Rating
                            name="rating"
                            value={snack.averageRating}
                            precision={0.5}
                            max={5}
                            size="medium"
                            readOnly
                        />
                        <Typography
                            sx={{ marginLeft: '0.3em', fontSize: '0.8em' }}
                            color="#4B5563"
                        >
                            ({snack.reviewsAmount})
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default SearchFieldResult;
